import styled from 'styled-components';

export const ImageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px 0 13px 16px;
`;

export const LabelContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const CarouselSlide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

export const CarouselImage = styled.div<CarouselImageProps>`
  height: 240px;
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  width: 100%;
  display: flex;
`;

type CarouselImageProps = {
  imageUrl: string;
};

export const CarouselContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px 16px 16px;
  gap: 8px;
`;

export const HorizontalLine = styled.div`
  border-radius: 4px;
  width: 100%;
  opacity: 0.5;
  border: 1px solid ${({ theme }) => theme.colors.gray.background};
`;

export const CarouselContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 4px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 15px;

  svg {
    width: 100%;
    height: 15px;
  }
`;
