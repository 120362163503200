import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';

import { media } from 'styles/utils';
import InstructorIcon from 'vectors/instructorIcon.svg';
import LocationMarker from 'vectors/locationMarker.svg';
import { Text } from 'common/typography';

export const LocationMarkerSvg = styled(LocationMarker)`
  width: 13.33px;
  height: 13.33px;

  ${media.desktop`
    width: 16px;
    height: 16px;
  `}
`;

export const InstructorSvg = styled(InstructorIcon)`
  width: 13.33px;
  height: 13.33px;

  ${media.desktop`
    width: 16px;
    height: 16px;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.33px;
  align-items: center;

  ${media.desktop`
    gap: 6px;
  `}
`;

export const BookingCardContainer = styled.div<BookingCardContainerProps>`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 6px solid transparent;
  border-color: transparent;
  position: relative;

  ${({ event }) => event && css`
    border-left: 6px solid ${({ theme }) => theme.colors.primary};
  `}
`;

type BookingCardContainerProps = {
  event: boolean;
};

export const CardWrapper = styled.div`
  display: flex;
  padding: 16px 12px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33%;
  padding-right: 16px;
`;

export const TimeText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

export const ClassTypeText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  ${media.desktop`
    font-size: 14px;
  `}
`;

export const VerticalLine = styled.div`
  min-height: 100%;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.colors.gray.light};
  width: 1px;
`;

export const ExtendedLink = styled(Link)`
  text-decoration: none;
`;
