import * as i from 'types';
import * as React from 'react';

import { generateClassesLabel, generateBookingLabel, isSerie } from 'services';

import { BookingLabelWrapper, ExtendedText, LabelContainer } from './styled';

export * from './styled';

export const ClassBookingLabel: React.FC<ClassBookingLabelProps> = ({ item }) => {
  const itemIsSerie = isSerie(item);
  const label = itemIsSerie ? generateClassesLabel(item) : generateBookingLabel(item);

  if (!label) return null;

  return (
    <LabelContainer>
      <BookingLabel variant={label} />
    </LabelContainer>
  );
};

type ClassBookingLabelProps = {
  item: i.Serie | i.Class;
}

export const BookingLabel: React.FC<BookingLabelProps> = ({ variant }) => {
  return (
    <BookingLabelWrapper backgroundColor={variant.backgroundColor}>
      <ExtendedText textColor={variant.textColor}>{variant.text}</ExtendedText>
    </BookingLabelWrapper>
  );
};

type BookingLabelProps = {
  variant: {
    text: string;
    backgroundColor: i.LabelColors;
    textColor?: i.TextColors;
  };
};
