import * as i from 'types';
import styled, { css } from 'styled-components';

import { Text } from 'common/typography';
import { getColor } from 'styles/utils';

export const BookingLabelWrapper = styled.div<LabelWrapperProps>`
  background-color: ${({ backgroundColor }) => getColor(backgroundColor)};
  width: fit-content;
  border-radius: 0px 0px 4px 4px;
  margin: 0 24px 0 0;
  padding: 0 8px;
`;

type LabelWrapperProps = {
  backgroundColor: i.LabelColors;
};

export const ExtendedText = styled(Text)<ExtendedTextProps>`
  line-height: 18px;
  font-size: 12px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;

  ${({ textColor }) => textColor && css`
    color: ${getColor(textColor)};
  `}
`;

type ExtendedTextProps = {
  textColor?: i.TextColors;
};

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 18px;
  position: absolute;
  right: 0;
`;

