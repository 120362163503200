import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

import { Text } from 'common/typography';
import { media } from 'styles/utils';

export const ExtendedText = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  margin: 0 0 0 32px;

  ${media.desktop`
    margin: 0 0 0 96px;
  `}
`;

export const ExtendedSwiper = styled(Swiper)`
  display: flex;
  flex-direction: column-reverse;
`;

export const CarouselContainer = styled.div`
  margin: 0 0 8px 32px;

  ${media.desktop`
    margin: 0 0 40px; 0;
  `}

  .carousel {
    width: 100%;
  }

  .carousel__slide {
    margin: 0;
    height: 254px;
    border-radius: 4px;
  }

  .carousel__placeholder {
    display: none;

    ${media.desktop`
      display: block;
      width: 85px!important;
    `}
  }
`;

export const SwiperContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ClassesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
`;

export const ProgressbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 32px 24px 32px;
`;

export const Progressbar = styled.div<ProgressBarProps>`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.medium};
  transition: .2s all;

  ${({ active }) => active && css`
    background-color: ${({ theme }) => theme.colors.gray.dark};
  `}
`;

type ProgressBarProps = {
  active?: boolean;
};
