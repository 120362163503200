import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby-plugin-react-i18next';

import { media } from 'styles/utils';

export const ExtendedLink = styled(Link)<ActiveProp>`
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: ${({ theme, $active }) => $active ? theme.colors.black : theme.colors.gray};
`;

type ActiveProp = {
  $active: boolean;
};

export const LanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 0 0 8px 32px;

  ${media.desktop`
    padding: 0 0 96px 96px;
  `}
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const NavigationContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
  position: relative;
  height: 100%;
  padding: 48px 0;
  width: 100%;
  position: fixed;
  top: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${media.desktop`
    width: 600px;
  `}
`;

export const IconContainer = styled(motion.div)`
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  padding-left: 24px;

  ${media.desktop`
    padding-left: 48px;
  `}
`;

export const NavigationItem = styled(motion.div)`
  & > a {
    font-size: 22px;
    line-height: 40px;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.primary};
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    position: relative;

    ${media.desktop`
      font-size: 30px;
    `}

    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 0;
      transition: width .3s ease;
      background: ${({ theme }) => theme.colors.black};
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &:hover:after {
      width: 100%;
    }
  }
`;

export const NavigationList = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 0 0 32px;
  flex: 1;

  ${media.desktop`
    gap: 12px;
    padding: 116px 32px 0 96px;
  `}
`;
