import * as React from 'react';
import { BLOCKS, INLINES, MARKS, Document, Inline } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';

import { Headline2, Headline3, Text } from 'common/typography';
import { ExternalLink } from 'common/interaction';

import { TextContainer } from './styled';

export const TextContent: React.FC<TextContentProps> = ({ content, alignment }) => {
  const documentContent = JSON.parse(content) as unknown as Document;

  const richTextOptions = {
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode): React.ReactNode => (
        <Text as="span" variant={['gray', 'medium']} $size={16} $lineHeight="24" $weight={400}>{text}</Text>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) => (
        <Text variant={['gray', 'medium']} $size={16} $lineHeight="24" $weight={200}>{children}</Text>
      ),
      [BLOCKS.HEADING_1]: (node: Node, children: React.ReactNode) => (
        <Headline2>{children}</Headline2>
      ),
      [BLOCKS.HEADING_2]: (node: Node, children: React.ReactNode) => (
        <Headline3>{children}</Headline3>
      ),
      [INLINES.HYPERLINK]: (node: Inline, children: React.ReactNode) => {
        if (
          node.data.uri.startsWith('mailto:')
          || node.data.uri.startsWith('tel:')
          || node.data.uri.startsWith('https:')
        ) {
          return (
            <ExternalLink href={node.data.uri}>
              {children}
            </ExternalLink>
          );
        }

        return (
          <GatsbyLink to={`/${node.data.uri}`}>
            {children}
          </GatsbyLink>
        );
      },
    },
    renderText: (text: string) => {
      return text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]);
    },
  };

  return (
    <TextContainer $align={alignment}>
      {documentToReactComponents(documentContent as unknown as Document, richTextOptions)}
    </TextContainer>
  );
};

type TextContentProps = {
  content: string;
  alignment?: 'left' | 'center' | 'right';
};
