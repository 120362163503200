import * as i from 'types';
import * as React from 'react';

import { BookingMeta, ClassBookingLabel } from 'modules/booking';
import { formatTime, formatWrittenDateYear, isSerie } from 'services';

import {
  BookingCardContainer,
  CardWrapper,
  TimeText,
  ClassTypeText,
  VerticalLine,
  ContentContainer,
  ExtendedLink,
} from './styled';

export const BookingCard: React.FC<BookingCardProps> = ({ item }) => {
  const itemIsSerie = isSerie(item);
  const hasEvent = itemIsSerie ? false : Boolean(item.event);
  const title = itemIsSerie ? item.classType : formatTime(item.classDate);
  const subTitle = itemIsSerie ? `${formatWrittenDateYear(item.classDate)}, ${formatTime(item.classDate)}` : item.classType;

  return (
    <ExtendedLink to={`/class/${itemIsSerie ? item.classId : item.id}`}>
      <BookingCardContainer event={hasEvent}>
        <ClassBookingLabel item={item} />
        <CardWrapper>
          <ContentContainer>
            <TimeText>{title}</TimeText>
            <ClassTypeText>{subTitle}</ClassTypeText>
          </ContentContainer>
          <VerticalLine />
          <BookingMeta
            site={item?.siteName}
            instructorName={item?.instructorName}
          />
        </CardWrapper>
      </BookingCardContainer>
    </ExtendedLink>
  );
};

type BookingCardProps = {
  item: i.Class | i.Serie;
};
